@import url('https://fonts.googleapis.com/css2?family=Inter&family=Manrope:wght@500&family=Montserrat:wght@500&display=swap');

@font-face {
  font-family: 'PP Telegraph';
  src: url('./fonts/PPTelegraf-Free\ for\ personal\ use/PPTelegraf-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica.ttf');
  font-weight: 500;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Inter', 'Manrope', 'Montserrat', 'PP Telegraph', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}